import {
  placeholderBackground,
  placeholderImage,
} from '@/shared/constants/icons';
import { getRandomNumber } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { IndexedList, Locale } from '@/shared/types/generic';
import { ItemType } from '@/shared/types/profile';
import { User } from '@/shared/types/user';
import getRandomReference from '@/shared/helpers/getRandomReference/getRandomReference';

export const mockUserProfile = () => ({
  showFtx: false,
  username: 'John Doe',
  language: Locale.US,
  privacyPolicy: true,
  termsConditions: true,
  changePassword: false,
  joinedAt: 1623345600000,
  coins: getRandomNumber(1000),
  points: getRandomNumber(1000),
  orgUserId: getRandomReference(),
  deviceToken: getRandomReference(),
  transactionCode: getRandomNumber(1000),
  activeBackground: {
    id: 1,
    name: '',
    description: '',
    url: placeholderImage,
    type: {
      name: '',
      id: ItemType.BACKGROUND,
    },
  },
  activeAvatar: {
    id: 1,
    name: '',
    description: '',
    url: placeholderImage,
    type: {
      name: '',
      id: ItemType.AVATAR,
    },
  },
});

export const mockUser = (
  reference = getRandomReference(),
  currentUser = false,
): User => ({
  siteName: '[site name]',
  urlAvatar: placeholderImage,
  username: getRandomReference(),
  urlBackground: placeholderBackground,
  reference,
  currentUser,
});

export const mockIndexedUsers = (
  userReferences = Array.from({ length: 10 }).map(() => getRandomReference()),
  name = '',
  isQueryByTeamName = false,
  numberOfTeams = 5,
  currentUserReference = '',
): IndexedList<User> => {
  const indexedUsers: IndexedList<User> = {};

  const filteredUserReferences: string[] = userReferences.filter(
    (reference: string) => reference.toLowerCase().includes(name.toLowerCase()),
  );

  if (isQueryByTeamName) {
    const indexes: string[] = [];

    Array.from({ length: numberOfTeams }).forEach((_, index) => {
      indexes.push(getRandomReference());

      indexedUsers[indexes[index]] = [];
    });

    filteredUserReferences.forEach((reference: string) =>
      indexedUsers[indexes[getRandomNumber(indexes.length - 1)]].push(
        mockUser(reference, reference === currentUserReference),
      ),
    );

    indexes.forEach((index: string) => {
      if (indexedUsers[index].length === 0) {
        delete indexedUsers[index];
      }
    });

    return indexedUsers;
  }

  filteredUserReferences.forEach((reference: string) => {
    const firstLetter = reference[0];

    if (!indexedUsers[firstLetter]) {
      indexedUsers[firstLetter] = [];
    }

    indexedUsers[firstLetter].push(
      mockUser(reference, reference === currentUserReference),
    );
  });

  return Object.keys(indexedUsers)
    .sort()
    .reduce((acc: IndexedList<User>, key: string) => {
      acc[key] = indexedUsers[key];

      return acc;
    }, {});
};
