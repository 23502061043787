import {
  LeaderboardItem,
  LeaderboardResponse,
} from '@/shared/types/leaderboard';
import { ApiResponse } from '@/shared/types/generic';
import {
  placeholderBackground,
  placeholderImage,
} from '@/shared/constants/icons';
import getRandomNames from '@/shared/helpers/getRandomNames/getRandomNames';
import { getRandomNumber } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import getRandomReference from '@/shared/helpers/getRandomReference/getRandomReference';

export const mockLeaderboardItem = (
  currentUser = false,
  position = getRandomNumber(100),
  points = getRandomNumber(100),
  siteName = 'Lisbon',
  name = getRandomNames(),
): LeaderboardItem => ({
  description: '',
  username: name,
  urlAvatar: placeholderImage,
  urlBackground: placeholderBackground,
  points,
  position,
  reference: getRandomReference(),
  currentUser,
  siteName,
});

export const mockLeaderboardItemList = (
  length = 4,
  withCurrentUser = false,
) => {
  const leaderboardList = Array.from({ length }).map((_, index) =>
    mockLeaderboardItem(false, index + 1),
  );

  if (withCurrentUser) {
    leaderboardList.push(mockLeaderboardItem(true));
  }

  return leaderboardList;
};

export const getLeaderboardResponse = (
  totalPlayers = 100,
  withCurrentUser = true,
): ApiResponse<LeaderboardResponse> => {
  const leaderboard = mockLeaderboardItemList(totalPlayers, withCurrentUser);

  const currentUser = leaderboard[leaderboard.length - 1];

  return {
    metadata: {
      totalItems: leaderboard.length,
      lastUpdated: 1650290653000,
    },
    results: {
      totalPlayers: leaderboard.length,
      currentUser,
      leaderboard,
    },
  };
};
