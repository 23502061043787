export function isEmpty(payload: unknown): boolean {
  return (
    payload === null ||
    payload === undefined ||
    (typeof payload === 'string' && payload.trim().length === 0) ||
    (Array.isArray(payload) && payload.length === 0) ||
    (typeof payload === 'object' &&
      !Array.isArray(payload) &&
      Object.keys(payload).length === 0)
  );
}

export default {};
