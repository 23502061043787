import { placeholderImage } from '@/shared/constants/icons';
import { getRandomNumber } from '@vaibe-github-enterprise/kd-kse-fe-commons';
import {
  CurrentUserReceivedKudoType,
  DisabledKudoTypesToGiveToUsers,
  KudoType,
  ReceivedKudoRecord,
} from '@/shared/types/kudos';
import getRandomReference from '@/shared/helpers/getRandomReference/getRandomReference';

const kudoTypeNames: KudoType['name'][] = [
  'GREAT_CO_WORKER',
  'GREAT_MENTOR',
  'TAKE_OWNERSHIP',
];

export const mockKudoType = (
  name = kudoTypeNames[getRandomNumber(kudoTypeNames.length - 1)],
): KudoType => ({
  reference: getRandomReference(),
  description: '',
  image: {
    name: '',
    url: placeholderImage,
    reference: getRandomReference(),
  },
  name,
});

const generatedKudoTypes = kudoTypeNames.map((kudoTypeName) =>
  mockKudoType(kudoTypeName),
);

export const mockKudoTypes = (): KudoType[] => generatedKudoTypes;

export const mockCurrentUserReceivedKudoTypes =
  (): CurrentUserReceivedKudoType[] =>
    Array.from(generatedKudoTypes).map((generatedKudoType: KudoType) => ({
      name: '',
      numberOfReceived: 4,
      reference: generatedKudoType.reference,
    }));

export const mockReceivedKudoRecords = (
  userReferences: string[],
): ReceivedKudoRecord[] => {
  const receivedKudoRecords: ReceivedKudoRecord[] = [];

  userReferences.forEach((userReference: string) => {
    generatedKudoTypes.forEach((kudoType) => {
      receivedKudoRecords.push({
        receivedDate: 100000,
        giverUserReference: userReference,
        giverUserName: 'giver name',
        giverUserAvatarUrl: placeholderImage,
        kudosTypeReference: kudoType.reference,
        kudosUserReference: getRandomReference(),
        kudosRead: Math.random() < 0.5,
      });
    });
  });

  return receivedKudoRecords;
};

export const mockDisabledKudoTypesToGiveToUsers = (
  references: string[],
): DisabledKudoTypesToGiveToUsers =>
  references.reduce((acc: Record<string, string[]>, reference: string) => {
    acc[reference] = generatedKudoTypes
      .map((kudoType: KudoType) => kudoType.reference)
      .slice(0, getRandomNumber(3));

    return acc;
  }, {});
